<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import store from '@/store';
import { ref } from '@vue/composition-api';
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue';

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const profileState = store.state.profile;
    const navMenuItems = ref([
      {
        header: 'Pages',
        icon: 'FileIcon',
        children: [
          {
            title: 'Home',
            route: 'home',
            icon: 'HomeIcon',
          },
          {
            title: 'userList',
            icon: 'UserIcon',
            disabled: profileState?.isProfileDisabled,
            route: 'apps-users-list',
          },
          {
            title: 'Trading',
            icon: 'RepeatIcon',
            disabled: profileState?.isProfileDisabled,
            children: [
              {
                title: 'Open Offers',
                route: 'trading-open-offers-list',
              },
              {
                title: 'offers',
                route: 'trading-offers-list',
              },
              {
                title: 'requests',
                route: 'trading-requests-list',
              },
              {
                title: 'myDeals',
                route: 'trading-deals-list',
              },
            ],
          },
          {
            title: 'notifications',
            route: 'apps-notifications',
            icon: 'BellIcon',
          },
          {
            title: 'myAccount',
            route: 'account-settings',
            icon: 'SettingsIcon',
          },
        ],
      },
    ]);
    return {
      navMenuItems,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
